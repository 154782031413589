// fetchDrmLicenseAvailability.js
'use strict';
import fetch from '../resource/customFetch.js';
import getMeData from '../selector/getMeData.js';
import getResourceUrl from '../resource/getResourceUrl.js';
import { getHeaders } from '../resource/fetchOptionHeader.js';
import handleFetchError from '../resource/handleFetchError.js';

import {
  ADD_MESSAGE,
  SET_NETWORKING_FETCHING,
  SET_NETWORKING_SUCCESS,
  SET_NETWORKING_ERROR,
} from '../ActionTypes.js';

/**
 * Fetch drm license availability
 * @kind action
 * @param {string} {keySystem} - drm keySystem type.
 * @param {string} {messageId} - message id.
 * @return {Promise} Action promise.
 */
const fetchDrmLicenseAvailability =
  ({ keySystem, messageId }) =>
  async (dispatch, getState) => {
    const token = getMeData(getState(), 'token');

    if (!messageId) {
      return dispatch({ type: '' });
    }

    const selectPath = ['drm', 'licenseAvailability', messageId];
    const fetchOptions = {
      method: 'HEAD',
      headers: {
        ...getHeaders(),
      },
    };
    if (token) {
      fetchOptions.headers['Authorization'] = `Bearer ${token}`;
    }
    const url = getResourceUrl({ endpoint: `/drm/authorize/${keySystem}` });
    url.searchParams.append('id', messageId);

    dispatch({ type: SET_NETWORKING_FETCHING, payload: { selectPath } });
    try {
      let response = await fetch(url.href, fetchOptions);
      if (!response.ok) {
        response = await handleFetchError({
          response,
          dispatch,
          getState,
          fetchOptions,
          fetchUrl: url,
        });
      }

      dispatch({
        type: ADD_MESSAGE,
        payload: {
          id: messageId,
          viewScope: 'sd',
        },
      });
      return dispatch({
        type: SET_NETWORKING_SUCCESS,
        payload: { selectPath },
      });
    } catch (error) {
      dispatch({
        type: ADD_MESSAGE,
        payload: {
          id: messageId,
          viewScope: 'preview',
        },
      });
      return dispatch({
        type: SET_NETWORKING_ERROR,
        payload: { selectPath, error },
      });
    }
  };

export default fetchDrmLicenseAvailability;
