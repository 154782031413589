// FlixCard.jsx
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import FlixPreview from '../container/FlixPreview.js';
import WithMessageMetaData from '../container/WithMessageMetaData.js';
import UserAvatarWithStatus from '../container/UserAvatarWithStatus.js';
import UserStatusIndicator from '../container/UserStatusIndicator.js';
import { LinkWithLanguage as Link } from '../component/LinkWithLanguage.jsx';
import HighlightSubstring from '../component/HighlightSubstring.jsx';
import Tooltip from '../component/Tooltip.jsx';
import formatNumber from '../resource/formatNumber.js';
import UnLockIconSrc from '../../img/ic_unlock_xs.svg';
import LikeIconSrc from '../../img/ic_like_xs.svg';
import ProIconSrc from '../../img/ic_pro_badge.svg';
import ViewersIconSrc from '../../img/ic_streaming_view_xs.svg';
import DiamondIconSource from '../../img/ic_diamond.svg';
import withViewportItemTracker from '../resource/withViewportItemTracker.js';
import { getKeySystem } from '../resource/getDrmInfo.js';
import { ButtonId } from '../resource/mixpanel.js';
import { LIVESTREAM_TYPE_LIVE_BADGE } from '../resource/userStatusIndicatorConstants.js';
import { textColor, color } from '../style/variables.js';
import media from '../style/media.js';

export class FlixCard extends React.PureComponent {
  state = {
    isHovered: false,
  };
  nextTicks = [];

  fetchDrmLicenseAvailability = () => {
    const {
      messageId,
      canViewMedia,
      isIntersecting,
      fetchDrmLicenseAvailability,
    } = this.props;

    if (!messageId || canViewMedia || !isIntersecting) return;

    this.nextTicks.push(
      setTimeout(() =>
        fetchDrmLicenseAvailability({
          messageId,
          keySystem: getKeySystem(),
        })
      )
    );
  };

  handleOnMouseEnter = () => {
    this.setState({ isHovered: true });
  };

  handleOnMouseLeave = () => {
    this.setState({ isHovered: false });
  };
  renderUsernameTooltip = () => {
    const { username, displayName, isDisplayNameEnabled } = this.props;
    return (
      <TooltipWrapper>
        {isDisplayNameEnabled && displayName
          ? displayName
          : username
            ? `@${username}`
            : ''}
      </TooltipWrapper>
    );
  };
  renderAvatar = () => {
    const { userId, enableTooltip, shouldShowOnline, shouldShowLiveStream } =
      this.props;

    return (
      <AvatarWrapper>
        <Tooltip
          position="bottom-center"
          renderHint={this.renderUsernameTooltip}
          trigger="hover"
          disabled={!enableTooltip}
        >
          <AvatarBlock>
            <UserAvatarWithStatus
              id={userId}
              size={36}
              circleOffset={2}
              liveStreamType={LIVESTREAM_TYPE_LIVE_BADGE}
              livestreamSize={14}
              shouldShowLiveStream={shouldShowLiveStream}
              shouldShowOnline={shouldShowOnline}
            />
          </AvatarBlock>
        </Tooltip>
      </AvatarWrapper>
    );
  };

  getIsPlaceholder = () => {
    const { messageId } = this.props;
    return messageId == null;
  };

  renderInfoBlock() {
    const {
      messageId,
      originalTitle,
      originalCaptionText,
      shouldShowUsername,
      shouldShowAvatar,
      shouldTrackEvent,
      isPro,
      hasFreePeek,
      isOnFreezone,
      hasAmateur,
      shouldShowUnlockCount,
      isDisplayNameEnabled,
      username,
      displayName,
      userId,
      matchedString,
      unlockPrice,
      canViewMedia,
      categoryIndex,
      categoryId,
      badges,
    } = this.props;
    const isPlaceholder = this.getIsPlaceholder();
    const shouldShowViewCount =
      !shouldShowUnlockCount || (hasFreePeek && isOnFreezone) || hasAmateur;

    const LinkProps = {
      to:
        isOnFreezone && hasFreePeek
          ? `/freezone/${messageId}`
          : `/post/${messageId}`,

      as: Link,
      'data-click_tracking_disabled': shouldTrackEvent ? false : true,
      'data-element_id': ButtonId.All.ButtonFlixCard,
      'data-tracking_payload': {
        messageId,
        'user.username': username,
        'discover.index': categoryIndex,
        'discover.category': categoryId,
        badges: badges?.join(','),
      },
    };

    const shownText = originalTitle || originalCaptionText || '-';

    return (
      <InfoWrapper>
        <Info>
          {shouldShowAvatar && this.renderAvatar()}
          <InfoRight {...(messageId ? LinkProps : {})}>
            <Caption isPlaceholder={isPlaceholder}>
              {isPro && (
                <ProIconWrapper>
                  <ProIcon
                    src={ProIconSrc}
                    width="12"
                    height="12"
                    alt="pro icon"
                  />
                </ProIconWrapper>
              )}
              <HighlightSubstring substring={matchedString}>
                {isPlaceholder ? '' : shownText}
              </HighlightSubstring>
            </Caption>
            {shouldShowUsername && (
              <UsernameAndStatus isPlaceholder={isPlaceholder}>
                <NameWrapper>
                  <DisplayName>
                    {isPlaceholder
                      ? ''
                      : isDisplayNameEnabled && displayName
                        ? displayName
                        : username
                          ? `@${username}`
                          : ''}
                  </DisplayName>
                  {isDisplayNameEnabled && displayName && (
                    <Username>{`@${username}`}</Username>
                  )}
                </NameWrapper>
                <UserStatusIndicatorWrapper>
                  <UserStatusIndicator
                    userId={userId}
                    shouldShowLiveStream
                    livestreamSize={12}
                    shouldShowOnline
                  />
                </UserStatusIndicatorWrapper>
              </UsernameAndStatus>
            )}
            {isPlaceholder ? null : (
              <>
                <WithMessageMetaData messageId={messageId}>
                  {({ likePercentage, unlocks, viewCount }) => (
                    <MetaData>
                      {shouldShowViewCount ? (
                        <>
                          <ViewsIcon
                            src={ViewersIconSrc}
                            alt="Views"
                            width="16"
                            height="16"
                          />
                          <ViewsNumber>{viewCount}</ViewsNumber>
                        </>
                      ) : (
                        <>
                          <UnLockIcon
                            src={UnLockIconSrc}
                            alt="Unlock"
                            width="16"
                            height="16"
                          />
                          <UnLockNumber>
                            {unlocks ? formatNumber(unlocks) : '-'}
                          </UnLockNumber>
                        </>
                      )}
                      <LikeIcon
                        src={LikeIconSrc}
                        alt="Like"
                        width="16"
                        height="16"
                      />
                      <LikeRateNumber>
                        {likePercentage ? `${likePercentage}%` : '-'}
                      </LikeRateNumber>
                    </MetaData>
                  )}
                </WithMessageMetaData>
                {unlockPrice > 0 && !canViewMedia && (
                  <UnlockPriceWrapper>
                    <UnlockPriceImg src={DiamondIconSource} alt="diamond" />
                    <UnlockPriceText>{unlockPrice}</UnlockPriceText>
                  </UnlockPriceWrapper>
                )}
              </>
            )}
          </InfoRight>
        </Info>
      </InfoWrapper>
    );
  }

  fetchMessageDetail = () => {
    const { messageId, badges, fetchMessageDetail } = this.props;
    if (messageId && !badges) {
      this.nextTicks.push(setTimeout(() => fetchMessageDetail({ messageId })));
    }
  };

  componentDidMount() {
    this.fetchMessageDetail();
    this.fetchDrmLicenseAvailability();
  }

  componentDidUpdate(prevProps) {
    const { messageId, isAuthed, canViewMedia, isIntersecting } = this.props;
    if (isAuthed !== prevProps.isAuthed || messageId !== prevProps.messageId) {
      this.fetchMessageDetail();
    }

    if (
      messageId !== prevProps.messageId ||
      canViewMedia !== prevProps.canViewMedia ||
      isIntersecting !== prevProps.isIntersecting
    ) {
      this.fetchDrmLicenseAvailability();
    }
  }

  componentWillUnmount() {
    this.nextTicks.forEach(clearTimeout);
  }

  render() {
    const {
      style,
      messageId,
      categoryId,
      categoryIndex,
      shouldTrackEvent,
      shouldUseLazyImage,
      shouldAnimateLoader,
      shouldPlay,
    } = this.props;
    const { isHovered } = this.state;

    return (
      <StyledFlixCard
        onMouseEnter={this.handleOnMouseEnter}
        onMouseLeave={this.handleOnMouseLeave}
        style={style}
      >
        <FlixPreview
          messageId={messageId}
          shouldPlay={isHovered || shouldPlay}
          categoryId={categoryId}
          categoryIndex={categoryIndex}
          shouldTrackCardEvent={shouldTrackEvent}
          shouldUseLazyImage={shouldUseLazyImage}
          shouldAnimateLoader={shouldAnimateLoader}
          shouldUseLink
          shouldShowLoader
        />
        {this.renderInfoBlock()}
      </StyledFlixCard>
    );
  }
}

FlixCard.propTypes = {
  categoryIndex: PropTypes.number,
  categoryId: PropTypes.string,
  originalTitle: PropTypes.string,
  originalCaptionText: PropTypes.string,
  messageId: PropTypes.string,
  shouldShowUsername: PropTypes.bool,
  shouldShowAvatar: PropTypes.bool,
  shouldAnimateLoader: PropTypes.bool,
  shouldTrackEvent: PropTypes.bool,
  shouldUseLazyImage: PropTypes.bool,
  shouldPlay: PropTypes.bool,
  enableTooltip: PropTypes.bool,
  isPro: PropTypes.bool,
  hasFreePeek: PropTypes.bool,
  isOnFreezone: PropTypes.bool,
  hasAmateur: PropTypes.bool,
  isAuthed: PropTypes.bool,
  shouldShowUnlockCount: PropTypes.bool,
  userId: PropTypes.string,
  username: PropTypes.string,
  displayName: PropTypes.string,
  badges: PropTypes.array,
  style: PropTypes.object,
  fetchMessageDetail: PropTypes.func,
  matchedString: PropTypes.string,
  unlockPrice: PropTypes.number,
  canViewMedia: PropTypes.bool,
  fetchDrmLicenseAvailability: PropTypes.func,
  shouldShowLiveStream: PropTypes.bool,
  shouldShowOnline: PropTypes.bool,
  isIntersecting: PropTypes.bool,
  isDisplayNameEnabled: PropTypes.bool,
};

FlixCard.defaultProps = {
  categoryIndex: null,
  categoryId: null,
  messageId: '',
  originalTitle: '',
  originalCaptionText: '',
  shouldShowUsername: false,
  shouldShowAvatar: false,
  shouldTrackEvent: false,
  shouldUseLazyImage: false,
  enableTooltip: false,
  shouldPlay: false,
  isPro: false,
  hasFreePeek: false,
  isOnFreezone: false,
  hasAmateur: false,
  isAuthed: false,
  shouldShowUnlockCount: false,
  userId: '',
  username: '',
  displayName: '',
  badges: null,
  fetchMessageDetail: () => null,
  matchedString: '',
  unlockPrice: null,
  canViewMedia: false,
  fetchDrmLicenseAvailability: () => null,
  shouldShowLiveStream: true,
  shouldShowOnline: true,
  isIntersecting: false,
  isDisplayNameEnabled: false,
};

const StyledFlixCard = styled.section`
  background: ${color.black};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  /* Can't set overflow hidden now because it crops Tooltips */
  /* overflow: hidden; */

  &:hover {
    opacity: 1;
  }
`;

const AvatarWrapper = styled.div`
  margin-right: 12px;
  display: flex;
  align-items: flex-start;
`;

const AvatarBlock = styled.div`
  display: block;
  :hover {
    opacity: 1;
  }
`;

const TooltipWrapper = styled.div`
  margin-top: 2px;
  padding: 4px;
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  background-color: rgb(246, 246, 246, 0.8);
  color: ${color.black};
  font-size: 12px;
  line-height: 1;
`;

const Caption = styled.div.attrs(({ isPlaceholder }) => ({
  style: {
    backgroundColor: isPlaceholder ? color.neutral[10] : 'transparent',
  },
}))`
  min-height: 16px;
  color: ${textColor.white};
  font-weight: 600;
  line-height: 1.4;
  margin-bottom: 4px;
  font-size: 16px;
  ${media.tablet`
    font-size: 12px;
  `}

  overflow: hidden;
  display: -webkit-box;
  white-space: pre-line;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const MetaData = styled.div`
  height: 24px;
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  ${media.tablet`
    height: 18px;
  `};
`;

const ViewsNumber = styled.div`
  padding-left: 4px;
  font-size: 16px;
  color: ${color.neutral[30]};
  ${media.tablet`
    font-size: 12px;
  `};
`;

const UnLockNumber = styled.div`
  padding-left: 4px;
  font-size: 16px;
  color: ${color.neutral[30]};
  ${media.tablet`
    font-size: 12px;
  `};
`;

const LikeRateNumber = styled.div`
  padding-left: 4px;
  font-size: 16px;
  color: ${color.neutral[30]};
  ${media.tablet`
    font-size: 12px;
  `};
`;

const ProIconWrapper = styled.div`
  position: relative;
  width: 12px;
  height: 12px;
  margin-right: 4px;
  display: inline-block;
`;

const ProIcon = styled.img`
  position: absolute;
  top: 2px;
  left: 0%;
`;

const ViewsIcon = styled.img`
  opacity: 0.2;
  width: 20px;
  height: 20px;
  ${media.tablet`
    width: 16px;
    height: 16px;
  `}
`;

const UnLockIcon = styled.img`
  opacity: 0.2;
  width: 20px;
  height: 20px;
  ${media.tablet`
    width: 16px;
    height: 16px;
  `}
`;

const LikeIcon = styled.img`
  margin-left: 8px;
  opacity: 0.2;
  width: 20px;
  height: 20px;
  ${media.tablet`
    width: 16px;
    height: 16px;
  `}
`;

const InfoWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  max-height: 150px;
  ${media.tablet`
    max-height: 120px;
  `}
`;

const Info = styled.div`
  padding: 12px 12px 8px 12px;
  width: 100%;
  display: flex;
  flex: auto;
  background-color: ${color.grey[900]};
  border-radius: 0 0 8px 8px;
  height: 100%;
`;

const InfoRight = styled.div`
  display: flex;
  flex: auto;
  flex-direction: column;
  overflow: hidden;
  &:hover {
    opacity: 1;
  }
`;

const UsernameAndStatus = styled.div.attrs(({ isPlaceholder }) => ({
  style: {
    backgroundColor: isPlaceholder ? color.neutral[10] : 'transparent',
    width: `${isPlaceholder ? 30 : 100}%`,
  },
}))`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 24px;
  margin-bottom: 4px;
  overflow: hidden;
  ${media.tablet`
    height: 18px;
  `}
`;

const NameWrapper = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  min-width: 0;
`;

const DisplayName = styled.span`
  font-size: 16px;
  font-weight: 600;
  color: ${textColor.white};
  margin-right: 2px;
  ${media.tablet`
    font-size: 12px;
  `}
`;

const Username = styled.span`
  color: ${color.grey[300]};
  font-size: 16px;
  line-height: 150%; /* 21px */
  letter-spacing: 0.014px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${media.tablet`
    font-size: 12px;
  `}
`;

const UserStatusIndicatorWrapper = styled.div`
  margin-left: 4px;
  flex: none;
`;

const UnlockPriceImg = styled.img`
  width: 16px;
  height: 16px;
  vertical-align: middle;
  margin-right: 2px;
`;

const UnlockPriceText = styled.span`
  vertical-align: middle;
`;

const UnlockPriceWrapper = styled.div`
  position: relative;
  font-size: 16px;
  color: ${color.tealBlue};
  ${media.tablet`
    font-size: 12px;
  `}
`;

export default withViewportItemTracker(FlixCard);
